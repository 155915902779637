html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 24px;
}

footer {
  position: absolute;
  bottom: 24px;
  width: 100%;
  height: 24px; /* Set the fixed height of the footer here */
}
